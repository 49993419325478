export const listDocumentsBySlug = /* GraphQL */ `
    query ListDocumentsBySlug(
        $slug: String!
        $sortDirection: ModelSortDirection
        $filter: ModelDocumentFilterInput
        $limit: Int
        $nextToken: String
    ) {
        listDocumentsBySlug(
            slug: $slug
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                slug
                category {
                    id
                    slug
                    icon
                    title
                    documents {
                        items {
                            id
                            title
                            slug
                        }
                    }
                }
                categoryID
                title
                description
                content
                isPublished
                createdAt
                updatedAt
            }
            nextToken
        }
    }
`;

export const listDocumentsByCategory = /* GraphQL */ `
    query ListDocumentsByCategory(
        $categoryID: ID!
        $createdAt: ModelStringKeyConditionInput
        $sortDirection: ModelSortDirection
        $filter: ModelDocumentFilterInput
        $limit: Int
        $nextToken: String
    ) {
        listDocumentsByCategory(
            categoryID: $categoryID
            createdAt: $createdAt
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                slug
                categoryID
                title
                description
                isPublished
                createdAt
                updatedAt
            }
            nextToken
        }
    }
`;

export const listDocumentCategories = /* GraphQL */ `
    query ListDocumentCategories(
        $id: ID
        $filter: ModelDocumentCategoryFilterInput
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection
    ) {
        listDocumentCategories(
            id: $id
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            sortDirection: $sortDirection
        ) {
            items {
                id
                title
            }
            nextToken
        }
    }
`;

export const createDocument = /* GraphQL */ `
    mutation CreateDocument(
        $input: CreateDocumentInput!
        $condition: ModelDocumentConditionInput
    ) {
        createDocument(input: $input, condition: $condition) {
            id
            slug
            categoryID
            title
            description
            isPublished
        }
    }
`;
export const updateDocument = /* GraphQL */ `
    mutation UpdateDocument(
        $input: UpdateDocumentInput!
        $condition: ModelDocumentConditionInput
    ) {
        updateDocument(input: $input, condition: $condition) {
            id
            slug
            category {
                id
                slug
                title
                description
                icon
                documents {
                    nextToken
                }
                createdAt
                updatedAt
            }
            categoryID
            title
            description
            content
            isPublished
            createdAt
            updatedAt
        }
    }
`;
export const deleteDocument = /* GraphQL */ `
    mutation DeleteDocument(
        $input: DeleteDocumentInput!
        $condition: ModelDocumentConditionInput
    ) {
        deleteDocument(input: $input, condition: $condition) {
            id
            slug
            category {
                id
                slug
                title
                description
                icon
                documents {
                    nextToken
                }
                createdAt
                updatedAt
            }
            categoryID
            title
            description
            content
            isPublished
            createdAt
            updatedAt
        }
    }
`;
