<template>
  <page-layout ref="layout">
    <template #breadcrumbs>
      <b-breadcrumb-item text="Support" :to="{ name: 'support' }"/>
      <b-breadcrumb-item text="Documentation" :to="{ name: 'support-docs' }" />
      <b-breadcrumb-item :text="document ? document.category.title : category" :to="{ name: 'support-docs-category', params: { slug: category } }" />
      <b-breadcrumb-item :text="document ? document.title : slug" active/>
    </template>

    <template #actions="{ state }">
      <b-button v-if="state.editing" size="sm" variant="outline-link" @click="state.editing = false">
        <feather-icon icon="EditIcon"/>
        <span class="align-middle ml-50">Editing</span>
      </b-button>
    </template>

    <template #dropdown-options="{ state }">
      <can do="update" on="support-docs">
        <b-dropdown-item @click="state.editing = !state.editing">
          <font-awesome-icon :icon="`fa-solid fa-${state.editing ? 'file-lines' : 'pen-to-square'}`"></font-awesome-icon>
          <span class="align-middle ml-50">{{ state.editing ? 'View' : 'Edit' }}</span>
        </b-dropdown-item>
      </can>
      <can do="delete" on="support-docs">
        <b-dropdown-item @click="$refs.layout.confirmDelete(null, deleteDocument, { confirm: { shouldParse: true, text: `Delete the <b>${document.title}</b> document?` } })">
          <font-awesome-icon icon="fa-solid fa-trash"></font-awesome-icon>
          <span class="align-middle ml-50">Delete</span>
        </b-dropdown-item>
      </can>
    </template>

    <template #content="{state}">
      <b-alert :show="enabled === false" variant="danger">
        This system is currently disabled, and only visible to those with the administrator privileges.
      </b-alert>

      <b-row>
        <b-col v-if="document">
          <template v-if="state.editing">
            <b-card v-if="document" no-body>
              <b-card-header header-class="">
                <b-card-title>Edit Document</b-card-title>
              </b-card-header>
              <b-card-body body-class="document-content">
                <b-form>
                  <b-row>
                    <b-col align-self="center">
                      <b-form-group label="Title" label-for="document-title">
                        <b-input id="document-title" v-model="document.title"></b-input>
                      </b-form-group>
                    </b-col>
                    <b-col align-self="center">
                      <b-form-group label="Category" label-for="document-category">
                        <v-select v-model="document.categoryID"
                                  input-id="document-category"
                                  :options="categories.items" label="title"
                                  :reduce="(option) => option.id"
                                  :loading="categories.loading"
                                  :clearable="false"/>
                      </b-form-group>
                    </b-col>
                    <b-col cols="auto" align-self="center">
                      <b-form-group label="Is Published?" label-for="document-is-published">
                       <b-checkbox id="document-is-published" v-model="document.isPublished" switch>
                         {{ document.isPublished ? 'Yes' : 'No' }}
                       </b-checkbox>
                      </b-form-group>
                    </b-col>
                    <b-col cols="12">
                      <b-form-group label="Description" label-for="document-description">
                        <b-input id="document-description" v-model="document.description"/>
                      </b-form-group>
                    </b-col>
                    <b-col cols="12">
                      <b-form-group>
                        <tip-tap v-model="document.content" />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" class="text-right">
                      <b-button variant="primary" class="mt-1 mr-1" @click="updateDocument">Update</b-button>
                      <b-button variant="secondary" class="mt-1" @click="state.editing = false">Cancel</b-button>
                    </b-col>
                  </b-row>
                </b-form>
              </b-card-body>
            </b-card>
          </template>
          <template v-else>
            <b-card v-if="$can('manage', 'support-docs') || document.isPublished" no-body>
              <b-card-header header-class="px-1">
                <div class="d-flex justify-content-between w-100 align-items-start">
                  <div class="d-flex align-items-center">
                    <b-avatar rounded class="mr-1" size="md" variant="light-primary">
                      <font-awesome-icon :icon="document.category.icon" size="lg"></font-awesome-icon>
                    </b-avatar>
                    <div>
                      <h5 class="mb-0">{{ document.title }}</h5>
                      <small v-if="document.description">{{ document.description }}</small>
                    </div>
                  </div>
                  <b-badge v-if="$can('manage', 'support-docs')" size="sm" :variant="document.isPublished ? 'primary' : 'danger'">
                    {{document.isPublished ? 'Published' : 'Unpublished' }}
                  </b-badge>
                </div>
              </b-card-header>
              <b-card-body body-class="document-content ProseMirror">
                <div v-html="document.content"></div>
              </b-card-body>
              <b-card-footer class="d-flex justify-content-end">
                <small class="text-muted">
                  <last-modified :date="document.updatedAt"/>
                </small>
              </b-card-footer>
            </b-card>
            <b-card v-else show variant="light">
              <div class="w-100 text-center">
                <h2 class="mb-1">
                  Page Not Found️
                </h2>
                <p class="mb-2">
                  Oops! 😖 The requested URL was not found on this server.
                </p>
              </div>
            </b-card>
          </template>
        </b-col>
      </b-row>
    </template>

    <template #debug>
      <b-row>
        <b-col v-if="$refs.layout">
          <debug>{{ $refs.layout.state }}</debug>
        </b-col>
        <b-col>
          <debug title="Active Document">
            {{ document }}
          </debug>
        </b-col>
        <b-col>
          <debug title="Create Document">
            {{ create.document }}
          </debug>
        </b-col>
        <b-col>
          <debug title="Categories">
            {{ categories }}
          </debug>
        </b-col>
        <b-col>
          <debug title="Documents">
            {{ documents }}
          </debug>
        </b-col>
      </b-row>
    </template>
  </page-layout>
</template>
<script>
import PageLayout from '@/components/PageLayout.vue';
import vSelect from 'vue-select';
import notify from '@/mixins/notify.mixin';
import {uuid} from 'vue-uuid';
import slugify from 'slugify';
import {API, graphqlOperation} from 'aws-amplify';
import {
  listDocumentCategories, listDocumentsBySlug, listDocumentsByCategory,
  createDocument, updateDocument, deleteDocument
} from '@/views/support/docs/queries/document-content';
import TipTap from '@/components/TipTap.vue';
import LastModified from '@/components/LastModified.vue';

export default {
  name: 'SupportDocumentContent',
  components: {
    LastModified,
    PageLayout,
    vSelect,
    TipTap
  },
  mixins: [ notify ],
  props: {
    category: {
      type: String,
      default: null,
      description: 'category slug'
    },
    slug: {
      type: String,
      required: true,
      description: 'document slug'
    },
    enabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      categories: {
        items: [],
        loading: false
      },
      document: null,
      documents: {
        items: [],
        loading: false
      },
      create: {
        document: {
          id: null,
          slug: null,
          categoryID: null,
          title: null,
          description: null,
          content: null,
          isPublished: false,
        },
      },
      activeTab: null,
      options: {
        modules: {
          toolbar: '#quill-toolbar',
        },
        placeholder: 'Reply...',
      }
    }
  },
  computed: {
    categoryDocuments() {
      if(this.category) {
        if(this.$can('manage', 'support-docs')) {
          return this.documents.items
        }
        return this.documents.items.filter(doc => doc.isPublished)
      }
      return []
    }
  },
  watch: {
    '$route.params.slug'(n, o) {
      if(n !== 'create') {
        this.listDocumentsBySlug(n)
        this.activeTab = this.documents.items.findIndex(item => item.slug === n)
      }
    }
  },
  async mounted() {
    await this.listDocumentsBySlug(this.slug)
    if(this.document) {
      await this.listDocumentsByCategory(this.document.categoryID)
      this.activeTab = this.documents.items.findIndex(item => item.slug === this.slug)
    }
    this.$refs.layout.state.loading = false
    await this.listDocumentCategories()
    this.activeTab = this.categoryDocuments.findIndex(item => item.slug === this.slug)
  },
  methods: {
    async listDocumentCategories(nextToken, pagedItems) {
      this.categories.loading = true

      const items = pagedItems || []
      const response = await API.graphql(graphqlOperation(listDocumentCategories, { limit: 500, nextToken: nextToken }));

      items.push(...response.data.listDocumentCategories.items)

      if(response.data.listDocumentCategories.nextToken) {
        await this.listDocumentCategories(response.data.listDocumentCategories.nextToken, items)
      }
      else {
        this.categories.items = items.sort((a, b) => a.title.localeCompare(b.title)).map((category, index) => ({...category, index: index, documents: { items: [], loading: false, loaded: false } }))
        this.categories.loading = false
      }
    },
    async listDocumentsByCategory(categoryID, nextToken, pagedItems) {
      this.documents.loading = true

      const items = pagedItems || []
      const response = await API.graphql(graphqlOperation(listDocumentsByCategory, { categoryID: categoryID, limit: 500, nextToken: nextToken }));

      items.push(...response.data.listDocumentsByCategory.items)

      if(response.data.listDocumentsByCategory.nextToken) {
        await this.listDocumentsByCategory(response.data.listDocumentsByCategory.nextToken, items)
      }
      else {
        this.documents.items = items.sort((a, b) => a.title.localeCompare(b.title))
        this.documents.loading = false
        this.documents.loaded = true
      }
    },
    async listDocumentsBySlug(slug, nextToken, pagedItems) {
      const items = pagedItems || []
      const response = await API.graphql(graphqlOperation(listDocumentsBySlug, { slug: slug, limit: 500, nextToken: nextToken }));

      items.push(...response.data.listDocumentsBySlug.items)

      if(response.data.listDocumentsBySlug.nextToken) {
        await this.listDocumentsBySlug(response.data.listDocumentsBySlug.nextToken, items)
      }
      else {
        // eslint-disable-next-line prefer-destructuring
        this.document = items.filter(document => document.category.slug === this.category)[0] || null
        //this.document.category.documents.items.sort((a, b) => a.title.localeCompare(b.title)).map((document, index) => ({...document, index: index }))
      }
    },

    async createDocument() {
      this.create.document.id = uuid.v4()
      this.create.document.slug = slugify(this.create.document.title, { lower: true })

      try {
        const response = await API.graphql(graphqlOperation(createDocument, { input: this.create.document }));
        const document = response.data.createDocument

        await this.$emit('created', document)
        if(document.categoryID === this.document.categoryID) {
          this.documents.items.push(document)
        }
        this.notify({ title: 'Success', text: 'Document was successfully created', icon: 'fas fa-server', variant: 'success' });
      }
      catch(e) {
        console.error(e)
        this.notify({ title: 'Error', text: 'Failed to create Document', icon: 'fas fa-server', variant: 'danger' });
      }
    },
    async updateDocument() {
      try {
        const input = {
          id: this.document.id,
          slug: slugify(this.document.title, { lower: true }),
          title: this.document.title,
          description: this.document.description,
          content: this.document.content,
          categoryID: this.document.categoryID,
          isPublished: this.document.isPublished
        }
        const response = await API.graphql(graphqlOperation(updateDocument, { input: input }));
        const document = response.data.updateDocument

        await this.$emit('updated', document)
        this.$refs.layout.state.editing = false
        this.notify({ title: 'Success', text: 'Document was successfully updated', icon: 'fas fa-server', variant: 'success' });
      }
      catch(e) {
        console.error(e)
        this.notify({ title: 'Error', text: 'Failed to update Document', icon: 'fas fa-server', variant: 'danger' });
      }
    },
    async deleteDocument() {
      try {
        const {document} = this
        const input = { id: document.id }
        await API.graphql(graphqlOperation(deleteDocument, { input: input }));
        //const document = response.data.deleteDocument
        //this.documents.items = this.documents.items.filter(item => item.id !== document.id)
        //await this.$emit('deleted', document)
        await this.$router.push({name: 'support-docs-category', params: { slug: document.category.slug }})
        this.notify({ title: 'Success', text: 'Document was successfully deleted', icon: 'fas fa-server', variant: 'success' });
      }
      catch(e) {
        console.error(e)
        this.notify({ title: 'Error', text: 'Failed to delete Document', icon: 'fas fa-server', variant: 'danger' });
      }
    },
    changeDocument(document) {
      if(this.slug !== document.slug) {
        this.$refs.layout.state.creating = false
        this.$refs.layout.state.editing = false
        this.$router.push({ params: { id: document.id, slug: document.slug}})
      }
    },
    toggleCreate() {
      this.$refs.layout.state.creating = true
      this.$router.push({ params: { id: '1', slug: 'create'}})
      this.create.document = {
        id: null,
        slug: null,
        categoryID: null,
        title: null,
        description: null,
        content: null,
        isPublished: false,
      }
    },
    backToCategory() {
      if(this.document) {
        this.$router.push({ name: 'support-docs-category', params: { id: this.document.category.id, slug: this.document.category.slug}})
      }
      else {
        this.$router.push({ name: 'support-docs-category', params: { slug: this.category}})
      }
    }
  }
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss">
.document-content {
  padding-left: 1.25rem;
}

.document-content .ql-editor {
  padding-left: 0;
  padding-right: 0;
}

.document-content #quill-toolbar {
  padding-left: 0;
  padding-right: 0;
}

#document-tabs .nav-link {
  justify-content: start;
}

@media print {
  #document-tabs-col {
    display: none;
  }
}

</style>
